import React from "react";
import bg from "../../images/product2bg.png";
import * as styles from "./productPartTwo.module.css";

export default function ProductPartTwo({ offsetY, clientHeight }) {
  const top = clientHeight * 5;
  const curr = top - offsetY

  return (
    <main className={styles.productPartTwo}>
      <img src={bg}
        className={styles.productTwoBg}
        alt="Angled snapshot of an example asset table"
      />

      <section
        className={styles.productTwoText}
        style={{
          transform: `translateY(${curr * .4}px)`
        }}
      >
        <h1>A centralized platform for your default portfolio.</h1>
        <h3>Track asset status and progress in the portfolio dashboard.</h3>
      </section>
    </main>
  )
}