import React from "react";
import taskContainer from "../../images/task-container.png";
import task0 from "../../images/task0.png";
import taskEdit from "../../images/task-edit.png";
import taskActivity from "../../images/task-activity.png";
import holdManagement from "../../images/hold-management.png"
import * as styles from "./mobileComment.module.css";

export default function Comment({ className, offsetY, clientHeight }) {
  return (
    <main
      id="features-collaborate"
      className={styles.commentContainer}
    >
      <section className={styles.textContainer}>
        <h3>Track tasks, communicate and collaborate with your team members.</h3>
        <img src={holdManagement}
          alt="Hold Management"
          style={{
            width: "50vw",
            marginBottom: "30px"
          }}
        />
      </section>

      <div style={{
        position: 'absolute',
        width: '100%',
        height: "50%",
        left: 0
      }}>
        <img src={taskContainer} className={styles.taskContainer} alt="Panel that contains tasks"/>
        <img src={task0} className={styles.task0} alt="Example task 1"/>
        <img src={taskEdit} className={styles.taskEdit}  alt="Panel to edit task"/>
        <img src={taskActivity} className={styles.taskActivity} alt="Comments on a specific task"/>
      </div>

    </main>
  )
}