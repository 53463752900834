import React from "react";
import bg from "../../images/experience-bg.png";
import classNames from "classnames";
import * as styles from "./experience.module.css";

export default function Experience({ offsetY, clientHeight }) {
  const top = clientHeight * 20.25;
  const curr = offsetY - top;

  return (
    <main className={styles.expContainer} id="experience">
      <section className={styles.expTopText}
        style={{
          transform: `translateY(${curr * .1}px)`,
        }}
      >
        <h1>Zoom in, zoom out.</h1>
        <h3>
          Manage and track everything all at once or focus just on the
          priority you have right now. Switch back and forth effortlessly.
        </h3>
      </section>

      <img src={bg} className={classNames(styles.experienceBg)}
        alt="Features of the app are seperated from the main image, spread out and layered."
        style={{
          opacity: 1 - (curr * .005)
        }}
      />
    </main>
  )
}