import React from "react";
import * as styles from "./footer.module.css";

export default function Footer({ hidden, darkMode }) {
  return (
    <footer className={styles.footer}
      style={{ background: darkMode && '#06060b', position: darkMode && 'absolute', visibility: hidden && 'hidden' }}
    >
      <div className={styles.footerContainer}>
        <span className={styles.brand}
          style={{ color: darkMode && 'white' }}
        >Jonah</span>
        <span className={styles.text}>
          @ {new Date().getFullYear()} Jonah. A Product of Redhawk Research, LLC.
          All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}
