import React from "react";
import classNames from "classnames";
import referral0 from "../../images/referral0.png";
import referral1 from "../../images/referral1.png";
import referral2 from "../../images/referral2.png";
import stateSpecific from "../../images/state-specific.png";
import * as styles from "./scale.module.css";

export default function Scale({ className, offsetY, clientHeight, isMobile }) {
  return (
    <main className={classNames(className, styles.scale)}
      id="features-processes"
      style={{ height: isMobile ? '100vh' : '250vh' }}
    >
      <section className={styles.textContainer}
        style={{ opacity: offsetY >= clientHeight*9 && 0 }}
      >
        <h3>Choose Judicial or Non-Judicial processes based on the State or case.</h3>
        <img src={stateSpecific}
          alt="State Specific Processes"
          style={{
            opacity: 1 - ((offsetY - (clientHeight*8.65))),
            width: '12vw',
            top: clientHeight < 1200 && `${65 - offsetY/clientHeight}%`
          }}
        />
      </section>

      <img src={referral0} className={styles.referralPic0} alt="List of assets that the user can upload"
        style={{ opacity: (!isMobile && offsetY <= clientHeight*6.8) || offsetY >= clientHeight*8.8 ? 0 : 1 }}
      />
      <img src={referral1} className={styles.referralPic1} alt="Dropdown that shows more options"
        style={{ opacity: (!isMobile && offsetY <= clientHeight*7.5) || offsetY >= clientHeight*8.8 ? 0 : 1 }}
      />
      <img src={referral2} className={styles.referralPic2} alt="Panel that allows users to refer the selected asset"
        style={{ opacity: (!isMobile && offsetY <= clientHeight*8) || offsetY >= clientHeight*8.8 ? 0 : 1 }}
      />

    </main>
  )
}