import React from "react";
import timeline0 from "../../images/timeline0.png";
import timeline1 from "../../images/timeline1.png";
import timelineText from "../../images/timeline-text.png";
import * as styles from "./mobileTimeline.module.css";

export default function MTimeline() {
  return (
    <main 
      id="features-timeline"
      className={styles.timelineContainer}
    >
      <div style={{
          position: 'absolute',
          width: '100%',
          height: "50%",
          left: 0
      }}>
        <img src={timeline0} className={styles.timeline0} alt="Timeline of an asset with a few events"/>
        <img src={timeline1} className={styles.timeline1} alt="Sidebar of the timeline with extra comments"/>
      </div>

      <section className={styles.textContainer}>
        <h3>Stay on top of milestones with timeline measurement.</h3>
        <img src={timelineText}
          alt="Comprehensive Event Log"
          style={{
            width: '60vw',
            paddingBottom: '30px'
          }}
        />
      </section>
    </main>
  )
}