import React, { useState, useRef, useEffect } from "react";
import Header from '../components/Header/Header.jsx';
import MobileHeader from "../components/Header/MobileHeader.jsx";
import Home from "../components/Home/Home.jsx";
import OurProduct from "../components/OurProduct/OurProduct.jsx";
import ProductPartTwo from "../components/OurProduct/ProductPartTwo.jsx";
import Features from "../components/Features/Features.jsx";
import MobileFeatures from "../components/MobileFeatures/MobileFeatures.jsx";
import MScale from "../components/MobileFeatures/MScale.jsx";
import MFolder from "../components/MobileFeatures/MFolder.jsx";
import MTimeline from "../components/MobileFeatures/MTimeline.jsx";
import MComment from "../components/MobileFeatures/MComment.jsx";
import MWeb from "../components/MobileFeatures/MWeb.jsx";
import Experience from "../components/Experience/Experience.jsx";
import ExperienceTwo from "../components/Experience/ExperienceTwo.jsx";
import DarkMode from "../components/DarkMode/DarkMode.jsx";
import Contact from "../components/Contact/Contact.jsx";
import Modal from '../components/Modal/Modal.jsx';
import Footer from "../components/Footer/Footer.jsx";
import { Helmet } from 'react-helmet';
import Loading from "../components/Loading/Loading.jsx";

const IndexPage = () => {
  const [open, setOpen] = useState(false);
  const [offsetY, setOffsetY] = useState(0);
  const [clientHeight, setClientHeight] = useState(968);
  const [isMobile, setIsMobile] = useState(false);
  const page = useRef(null);

  const darkMode = isMobile ? offsetY > clientHeight * 8 : offsetY > clientHeight * 22;
  const hideHeader = isMobile ? offsetY > clientHeight * 9.1 : offsetY > clientHeight * 23;
  const hideFooter = offsetY > clientHeight * 21;

  const handleScroll = () => {
    const { current } = page;
    const { scrollTop, clientHeight } = current;

    setOffsetY(scrollTop);
    setClientHeight(clientHeight);
  };

  useEffect(() => {
    const { current } = page;
    setIsMobile(current.getBoundingClientRect().width < 500);
  }, [])

  useEffect(() => {
    const { current } = page;
    if (offsetY === 0) setOffsetY(current.scrollTop);
    current.addEventListener('scroll', handleScroll);
    return () => current.removeEventListener('scroll', handleScroll);
  }, [offsetY])

  return (
    <div className="relative bg-white h-fit w-fit overflow-scroll flex-col">
      <div className="mainContainer" ref={page}>
        <Helmet title={'Jonah Direct'}/>
        <Loading />

        {isMobile
          ? (<MobileHeader page={page} darkMode={darkMode} hidden={hideHeader} setOpen={setOpen}/>)
          : (<Header hidden={hideHeader} darkMode={darkMode} offsetY={offsetY} clientHeight={clientHeight}/>)
        }

        <Home clientHeight={clientHeight} setOpen={setOpen} offsetY={offsetY} isMobile={isMobile}/>

        {!isMobile && (
          <>
            <OurProduct offsetY={offsetY} clientHeight={clientHeight}/>
            <ProductPartTwo offsetY={offsetY} clientHeight={clientHeight} />
          </>
        )}
        {isMobile
          ? (
            <>
              <MobileFeatures clientHeight={clientHeight} offsetY={offsetY} isMobile={isMobile}/>
              <MScale/>
              <MFolder offsetY={offsetY} clientHeight={clientHeight} isMobile={isMobile}/>
              <MTimeline offsetY={offsetY} clientHeight={clientHeight} isMobile={isMobile}/>
              <MComment offsetY={offsetY} clientHeight={clientHeight} isMobile={isMobile}/>
              <MWeb offsetY={offsetY} clientHeight={clientHeight} isMobile={isMobile}/>
            </>
          )
          : <Features clientHeight={clientHeight} offsetY={offsetY} isMobile={isMobile}/>
        }
        
        {!isMobile && (
          <>
            <Experience offsetY={offsetY} clientHeight={clientHeight}/>
            <ExperienceTwo offsetY={offsetY} clientHeight={clientHeight}/>
          </>
        )}
        <DarkMode darkMode={darkMode} isMobile={isMobile} />
        <Contact setOpen={setOpen} isMobile={isMobile}/>
      </div>
      
      <Footer hidden={hideFooter}/>
      <Modal openModal={open} setOpenModal={setOpen}/>
    </div>
  );
};

export default IndexPage;
