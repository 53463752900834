import React from "react";
import classNames from "classnames";
import web0 from "../../images/web0.png";
import web1 from "../../images/web1.png";
import * as styles from "./web.module.css";

export default function Web({ className, offsetY, clientHeight }) {

  return (
    <main
      id="features-assets"
      className={classNames(className, styles.webContainer)}
      style={{ opacity: offsetY <= clientHeight*17.5 ? 0 : 1, transition: 'opacity .5s'}}
    >
      <img src={web0} className={styles.web0} alt="Application in loading state"
        style={{ opacity: offsetY >= clientHeight*19.1 ? 0 : 1 }}
      />
      <img src={web1} className={styles.web1} alt="Panel that allows users to import assets"
        style={{ opacity: offsetY <= clientHeight*18.4 || offsetY >= clientHeight*19.1 ? 0 : 1 }}
      />

      <section
        style={{ opacity: offsetY >= clientHeight*19.1 ? 0 : 1, transition: 'opacity .33s' }}
      >
        <h3>Leverage API's to update portfolio data easily.</h3>
      </section>
    </main>
  )
}