import React from 'react'

export default function MiniLogo({ onClick }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <circle cx="16" cy="16" r="16" fill="#106AF1"/>
      <path
        d="M20.1968 31.444C26.9978 29.6004 32.0003 23.3844 32.0003 16C32.0003 15.9586 32.0002 15.9173 31.9999 15.876H27.0391C24.2991 15.876 22.0778 18.1085 22.0778 20.8372C22.0778 21.2093 21.9538 21.8295 21.3337 21.8295C20.7135 21.8295 20.5895 21.2093 20.5895 20.8372C20.5895 18.1085 18.3682 15.876 15.6282 15.876H10.667V20.8372C10.667 26.3442 14.8403 30.8765 20.1968 31.444Z"
        fill="white"
      />
    </svg>
  )
}
