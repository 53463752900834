import React from "react";
import docs0 from "../../images/docs0.png";
import docs1 from "../../images/docs1.png";
import * as styles from "./mobileFolder.module.css";

export default function MFolder() {
  return (
    <main 
      id="features-documents"
      className={styles.folderContainer}
    >
      <div style={{
        position: 'absolute',
        width: '100%',
        height: "50%",
        left: 0
      }}>
        <img src={docs0} className={styles.docs0} alt="Documents that can be uploaded by users."/>
        <img src={docs1} className={styles.docs1} alt="Panel to edit documents"/>
      </div>
  
      <section className={styles.textContainer}>
        <h3>Upload and track documents in a secure document repository.</h3>
      </section>
    </main>
  )
};