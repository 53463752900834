import React from "react";
import { AtIcon, PhoneIcon } from "../icons/icons.jsx";
import MiniLogo from "../svg/MiniLogo"
import bg from "../../images/contact-bg.png"
import * as styles from "./contact.module.css";

export default function Contact({ setOpen, isMobile }) {
  const handleClick = () => {
    if (isMobile) setOpen(true);
    else window.open("https://app.jonahdirect.com/auth/register")
  }

  return (
    <div id="contact" className={styles.contactContainer}>
      <header className={styles.contactHeader}>
        <a href="#home">
          <MiniLogo />
        </a>

        <nav className={styles.contactHeaderButtons}>
          <a
            className={styles.backToTop}
            href="#home"
          >Back to top</a>
          {!isMobile && 
              <button
              className={styles.contactSignIn}
              onClick={handleClick}
            >Sign Up</button>
          }
        </nav>
      </header>

      {!isMobile && <img src={bg} className={styles.contactBg} alt="Faded shot of Jonah Direct in the corner of the screen."/> }

      <main className={styles.contactSidebar}>
        <section>
          <h1>Manage your default portfolio with precision.</h1>
          {!isMobile && (
            <nav className={styles.contactList}>
              <a
                href="#home"
              >Home</a>
              <a
                href="#product"
              >Our Product</a>
              <a
                href="#features"
              >Features</a>
              <a
                href="#experience"
              >Experience</a>
            </nav>
          )}
        </section>

        <footer className={styles.contactFooter}>
          <a href="tel:9493850771" className={styles.phoneLink}>
            <span className={styles.icon}>
              <PhoneIcon />
            </span>
            <span className={styles.box}>(949) 385-0771</span>
          </a>

          <a href="mailto:info@redhawkresearch.com" className={styles.emailLink}>
            <span className={styles.icon}>
              <AtIcon />
            </span>
            <span className={styles.box}>info@redhawkresearch.com</span>
          </a>
          <span className={styles.contactFooter}>© 2022, Jonah. A Product of Redhawk Research, LLC. All Rights Reserved.</span>
        </footer>
      </main>
    </div>
  );
}
