/* eslint-disable no-restricted-globals */
import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useEffect } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import * as styles from "./modal.module.css";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export const checkURLRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

export function isRegexValid(url, regex) {
  const result = url.match(regex) ? true : false;
  return result;
}

export default function Modal({ openModal, setOpenModal }) {
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [formError, setFormError] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (openModal !== false) {
      setOpen(true);
    }
  }, [openModal]);

  function validateidentifier(identifier) {
    if (!isRegexValid(identifier, checkURLRegex)) {
      setFormError(true);
      return true;
    } else {
      setFormError(false);
      return false;
    }
  }

  function showThankYouMessage() {
    // do something here
    alert("Thank you for subscribing!");
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (!validateidentifier(email)) {
      // handle send email via netifly here
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          email,
          "form-name": "redhawk-early-access"
        }),
      })
        .then(() => showThankYouMessage())
        .catch((error) => setFormError(error));
      setOpen(false);
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={styles.modal}
        onClose={() => {
          setOpen(false);
          setOpenModal(false);
        }}
      >
        <div className={styles.backdrop}>
          <Transition.Child
            as={Fragment}
            enter={styles.enterBackdrop}
            enterFrom={styles.enterFromBackdrop}
            enterTo={styles.enterToBackdrop}
            leave={styles.leaveBackdrop}
            leaveFrom={styles.leaveFromBackdrop}
            leaveTo={styles.leaveToBackdrop}
          >
            <Dialog.Overlay className={styles.overlayBackdrop} />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className={styles.centerModal}
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter={styles.enterModal}
            enterFrom={styles.enterFromModal}
            enterTo={styles.enterToModal}
            leave={styles.leaveModal}
            leaveFrom={styles.leaveFromModal}
            leaveTo={styles.leaveToModal}
          >
            <div className={styles.modalContainer}>
              <div>
                <div className={styles.modalContent}>
                  <Dialog.Title
                    as="h3"
                    className={styles.modalTitle}
                  >
                    Early Access
                  </Dialog.Title>
                  <div>

                    <form
                      className="space-y-6"
                      onSubmit={handleSubmit}
                      data-netlify="true"
                      name="redhawk-early-access"
                      method="post"
                    >
                    <input type="hidden" name="form-name" value="redhawk-early-access" />
                      <div>
                        <div className="flex justify-between mb-2">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-500"
                          >
                            E-mail
                          </label>
                          <span className="block text-sm font-medium text-gray-500">
                            Mandatory
                          </span>
                        </div>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className={classNames(
                              formError
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
                                : "border-gray-300  placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500",
                              "rounded-md shadow-sm appearance-none block w-full px-3 py-2 border sm:text-sm"
                            )}
                            placeholder="Enter e-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={() => validateidentifier(email)}
                          />
                          {formError && (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <ExclamationCircleIcon
                                className="h-5 w-5 text-red-500"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                        {formError && (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="email-error"
                          >
                            Your must provide an email.
                          </p>
                        )}
                      </div>

                      <div className="mt-5 sm:mt-6 ">
                        <div className="relative mb-6 flex justify-center items-center border-2 border-dotted rounded-sm p-2 bg-blue-50" style={{cursor: "pointer"}} onClick={() => setIsChecked(!isChecked)}>
                          <div className="flex items-center h-5 relative">
                            <input
                              id="comments"
                              aria-describedby="comments-description"
                              name="comments"
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              style={{cursor: "pointer"}}
                              checked={isChecked}
                              onChange={() => setIsChecked(!isChecked)}
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <p
                              id="comments-description"
                              className="text-gray-500"
                            >
                              I agree to receive Jonah Direct marketing
                              communications: announcements, product updates and
                              promotions.
                            </p>
                          </div>
                        </div>
                        <button
                          disabled={!isChecked}
                          type="submit"
                          className={classNames(
                            isChecked
                              ? " focus:ring-indigo-500  bg-blue-600 hover:bg-indigo-700  "
                              : "bg-gray-500",
                            "w-full rounded-md border border-transparent shadow-sm px-12 py-3 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
                          )}
                        >
                          Join
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
