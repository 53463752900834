import React from 'react';
import headerText from '../../images/headerText.png';
import darkHeader from "../../images/dark-headerText.png";
import * as styles from "./header.module.css";

export default function Header({ hidden, darkMode, offsetY, clientHeight }) {
  const clickSignIn = () => {
    window.open("https://app.jonahdirect.com/auth/login")
  }

  const isActive = (top, bottom) => {
    if (offsetY >= top && offsetY <= bottom) return true;
    else return false;
  }

  return (
    <nav
      className={styles.headerContainer}
      style={{background: darkMode && 'none', visibility: hidden && 'hidden'}}
    >
      <a href="#home"
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}
      >
        <img src={darkMode ? darkHeader : headerText} className={styles.headerLogo} height="100px" alt="stylized text of product, Jonah Direct"/>
      </a>

      <ul className={styles.rightNav}>
        <a
          className={`${styles.link} ${isActive(clientHeight * 2, clientHeight * 5.5) && styles.active}`}
          style={{color: darkMode && 'gray'}}
          href="#product"
        >Our Product</a>
        <a
          className={`${styles.link} ${isActive(clientHeight*5.5, clientHeight*20) && styles.active}`}
          style={{color: darkMode && 'gray'}}
          href="#features"
        >Features</a>
        <a
          className={`${styles.link} ${isActive(clientHeight*20, clientHeight*22) && styles.active}`}
          style={{color: darkMode && 'gray'}}
          href="#experience"
        >Experience</a>
        <a
          className={styles.link}
          style={{color: darkMode && 'gray'}}
          href="#contact"
        >Contact</a>

        <button onClick={clickSignIn} className={styles.ctaButton}>
          Sign In
        </button>
      </ul>
    </nav>
  )
};
