import React from "react";

export const ClockRotateIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0C18.6281 0 24 5.37188 24 12C24 18.6281 18.6281 24 12 24C9.45469 24 7.0875 23.2031 5.14219 21.8484C4.4625 21.3328 4.29563 20.4375 4.77188 19.7578C5.24531 19.0781 6.17813 18.9141 6.85781 19.3875C8.31563 20.4047 10.0875 21 12 21C16.9688 21 21 16.9688 21 12C21 6.98906 16.9688 3 12 3C9.47344 3 7.26562 4.00594 5.63437 5.63437L7.07812 7.07812C7.78594 7.78594 7.28438 9 6.28594 9H1.125C0.503906 9 0 8.49844 0 7.875V2.71594C0 1.71375 1.21172 1.21172 1.92047 1.92047L3.51469 3.51469C5.68594 1.34484 8.68594 0 11.9578 0H12ZM12 6C12.6234 6 13.125 6.50156 13.125 7.125V11.5359L16.1297 14.5781C16.6078 15.0188 16.6078 15.7313 16.1297 16.1297C15.7313 16.6078 15.0188 16.6078 14.5781 16.1297L11.2031 12.7547C10.9922 12.5859 10.875 12.3 10.875 12V7.125C10.875 6.50156 11.3766 6 12 6Z"
      fill="black"
    />
  </svg>
);

export const TimelineIcon = ({ color }) => (
  <svg
    width="30"
    height="24"
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 10.5H22.5V7.93594C21.1734 7.31719 20.25 6.0375 20.25 4.5C20.25 2.42906 21.9281 0.75 24 0.75C26.0719 0.75 27.75 2.42906 27.75 4.5C27.75 6.0375 26.8266 7.31719 25.5 7.93594V10.5H28.5C29.3297 10.5 30 11.1703 30 12C30 12.8297 29.3297 13.5 28.5 13.5H16.5V16.0641C17.8266 16.6406 18.75 17.9625 18.75 19.5C18.75 21.5719 17.0719 23.25 15 23.25C12.9281 23.25 11.25 21.5719 11.25 19.5C11.25 17.9625 12.1734 16.6406 13.5 16.0641V13.5H1.5C0.671719 13.5 0 12.8297 0 12C0 11.1703 0.671719 10.5 1.5 10.5H4.5V7.93594C3.17578 7.31719 2.25 6.0375 2.25 4.5C2.25 2.42906 3.92906 0.75 6 0.75C8.07187 0.75 9.75 2.42906 9.75 4.5C9.75 6.0375 8.82656 7.31719 7.5 7.93594V10.5ZM6 5.625C6.62344 5.625 7.125 5.12344 7.125 4.5C7.125 3.87891 6.62344 3.375 6 3.375C5.37656 3.375 4.875 3.87891 4.875 4.5C4.875 5.12344 5.37656 5.625 6 5.625ZM24 3.375C23.3766 3.375 22.875 3.87891 22.875 4.5C22.875 5.12344 23.3766 5.625 24 5.625C24.6234 5.625 25.125 5.12344 25.125 4.5C25.125 3.87891 24.6234 3.375 24 3.375ZM15 20.625C15.6234 20.625 16.125 20.1234 16.125 19.5C16.125 18.8766 15.6234 18.375 15 18.375C14.3766 18.375 13.875 18.8766 13.875 19.5C13.875 20.1234 14.3766 20.625 15 20.625Z"
      fill={color}
    />
  </svg>
);

export const FlagIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5 2.88891V1.44094C24.5 0.269062 23.1495 -0.339844 22.0076 0.199219C13.3578 4.28906 14.0703 -2.91797 3.49998 1.51781V1.5C3.49998 0.667969 2.83201 0 1.99998 0C1.16795 0 0.499983 0.667969 0.499983 1.5L0.498108 23.25C0.498108 23.6602 0.837951 24 1.20592 24H2.74998C3.16014 24 3.49998 23.6625 3.49998 23.25V18C12.875 13.6758 14.6937 20.4961 23.5672 16.9163C24.139 16.6828 24.5 16.1438 24.5 15.5672V13.8797C22.2978 14.6883 20.449 15.0103 18.8375 15.0103C16.9508 15.0045 15.3608 14.6119 13.789 14.2313C10.9062 13.5234 8.15466 12.8812 3.49998 14.7562V13.1391C8.28123 11.3752 11.3047 12.0727 14.2062 12.7816C17.0515 13.4906 19.8453 14.1281 24.5 12.2578V9.375C22.2978 10.1836 20.449 10.5056 18.8375 10.5056C16.9508 10.5056 15.3608 10.113 13.789 9.73219C10.9062 9.02344 8.15466 8.38125 3.49998 10.2563V8.63906C8.28123 6.88125 11.3047 7.57875 14.2062 8.2875C17.0515 8.99063 19.8453 9.62813 24.5 7.7625V4.53516C21.7929 5.63672 19.7937 6.00609 18.1343 6.00609C15.1831 6.00609 12.5609 4.73625 12.5 4.72266V3.16406C14.5578 3.70031 17.2859 6.01406 24.5 2.88891ZM6.49998 6.375C6.08982 6.375 5.74998 6.04102 5.74998 5.625C5.74998 5.20898 6.08982 4.875 6.49998 4.875C6.91013 4.875 7.24998 5.20898 7.24998 5.625C7.24998 6.04102 6.91248 6.375 6.49998 6.375ZM6.49998 3.375C6.08982 3.375 5.74998 3.04688 5.74998 2.625C5.74998 2.21484 6.08982 1.875 6.49998 1.875C6.91013 1.875 7.24998 2.20898 7.24998 2.625C7.24998 3.04102 6.91248 3.375 6.49998 3.375ZM9.49998 6C9.09216 6 8.74998 5.66719 8.74998 5.25C8.74998 4.83281 9.09216 4.5 9.49998 4.5C9.90779 4.5 10.25 4.82812 10.25 5.25C10.25 5.6625 9.91247 6 9.49998 6ZM9.49998 3.01172C9.08982 3.01172 8.74998 2.68359 8.74998 2.26172C8.74998 1.85156 9.08982 1.51172 9.49998 1.51172C9.91013 1.51172 10.25 1.8457 10.25 2.26172C10.25 2.67773 9.91247 3.01172 9.49998 3.01172Z"
      fill="black"
    />
  </svg>
);

export const ScaleIcon = ({ color }) => (
  <svg
    width="30"
    height="24"
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.011 7.24219C25.185 5.58984 22.8178 5.58375 21.9858 7.24219C17.9077 15.4125 18.0436 15.0188 18.0436 15.75C18.0436 17.8186 20.7272 19.5 24.0436 19.5C27.36 19.5 30.0014 17.8181 30.0014 15.75C29.9579 14.9953 30.0657 15.3422 26.011 7.24219ZM20.5828 15L23.956 8.25L27.3389 15H20.5828ZM12 15.75C12 14.9944 12.0645 15.3398 8.01 7.24219C7.18406 5.58984 4.81687 5.58375 3.98484 7.24219C-0.0932925 15.4125 0.0426453 15.0188 0.0426453 15.75C0.0426453 17.8186 2.72624 19.5 6.00047 19.5C9.27469 19.5 12 17.8172 12 15.75ZM5.99531 8.25L9.37969 15H2.62312L5.99531 8.25ZM23.2078 21H16.5V7.18594C17.6016 6.70312 18.4266 5.70937 18.675 4.5H24C24.8283 4.5 25.4578 3.82828 25.4578 3C25.4578 2.17172 24.7861 1.5 24 1.5H17.9813C17.296 0.591563 16.2235 0 14.9991 0C13.7747 0 12.6609 0.591563 12.0188 1.5H6C5.17031 1.5 4.5 2.17172 4.5 3C4.5 3.82828 5.17031 4.5 5.95781 4.5H11.2828C11.5289 5.70703 12.3549 6.70312 13.4564 7.18359V21H6.75C5.50734 21 4.49953 22.0073 4.49953 23.25C4.49953 23.6642 4.83539 24 5.24953 24H24.7496C25.1637 24 25.4996 23.6642 25.4996 23.25C25.5 22.0078 24.4922 21 23.2078 21Z"
      fill={color}
    />
  </svg>
);

export const HandIcon = () => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5 6V15.75C22.5 20.2992 18.7992 24 14.25 24H12.4819C9.97781 24 7.62094 23.025 5.85375 21.2541L0.552187 15.9525C0.183094 15.5859 0 15.1031 0 14.625C0 13.6266 0.803906 12.75 1.875 12.75C2.35453 12.75 2.83453 12.9331 3.20062 13.2994L6 16.0969V3C6 2.17172 6.67172 1.5 7.5 1.5C8.32828 1.5 9 2.17172 9 3L9.00342 11.25C9.00469 11.6625 9.3375 12 9.75 12C10.1625 12 10.5033 11.6642 10.5033 11.25L10.5 1.5C10.5 0.671719 11.1717 0 12 0C12.8283 0 13.5 0.671719 13.5 1.5L13.5023 11.25C13.5023 11.6642 13.8356 12 14.2499 12C14.6643 12 15 11.6625 15 11.25V3C15 2.17172 15.6717 1.5 16.5 1.5C17.3283 1.5 18 2.17172 18 3L18.0034 11.25C18.0034 11.6642 18.3358 12 18.7501 12C19.1645 12 19.5 11.6625 19.5 11.25V6C19.5 5.17172 20.1717 4.5 21 4.5C21.8283 4.5 22.5 5.17031 22.5 6Z"
      fill="black"
    />
  </svg>
);

export const FileFolderIcon = ({ color }) => (
  <svg
    width="27"
    height="22"
    viewBox="0 0 27 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.92813 8H22.5V5.75C22.5 4.50781 21.4922 3.5 20.25 3.5H12.75L9.75 0.5H2.25C1.00734 0.5 0 1.50734 0 2.75V18.1437L4.24406 9.65469C4.75313 8.6375 5.78438 8 6.92813 8ZM25.4578 9.5H6.92813C6.36094 9.5 5.84062 9.81875 5.5875 10.3297L0 21.5H20.9578C21.5259 21.5 22.0453 21.1788 22.2994 20.6708L26.7994 11.6708C27.3375 10.6719 26.6109 9.5 25.4578 9.5Z"
      fill={color}
    />
  </svg>
);

export const WebIcon = ({ color }) => (
  <svg
    width="30"
    height="24"
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4437 1.91203L13.4437 22.9125C13.2141 23.7094 12.3844 24.1688 11.5875 23.9438C10.7906 23.7141 10.3313 22.8844 10.5562 22.0875L16.5563 1.08797C16.7859 0.291375 17.6156 -0.169875 18.4125 0.0577497C19.2094 0.285328 19.6687 1.11563 19.4437 1.91203ZM24.3094 5.69063L29.5594 10.9406C30.1453 11.5266 30.1453 12.4734 29.5594 13.0594L24.3094 18.3094C23.7234 18.8953 22.7766 18.8953 22.1906 18.3094C21.6047 17.7234 21.6047 16.7766 22.1906 16.1906L26.3766 12L22.1906 7.80938C21.6047 7.22344 21.6047 6.27656 22.1906 5.69063C22.7766 5.10469 23.7234 5.10469 24.3094 5.69063ZM7.80938 7.80938L3.62109 12L7.80938 16.1906C8.39531 16.7766 8.39531 17.7234 7.80938 18.3094C7.22344 18.8953 6.27656 18.8953 5.69063 18.3094L0.439312 13.0594C-0.146438 12.4734 -0.146438 11.5266 0.439312 10.9406L5.69063 5.69063C6.27656 5.10469 7.22344 5.10469 7.80938 5.69063C8.39531 6.27656 8.39531 7.22344 7.80938 7.80938Z"
      fill={color}
    />
  </svg>
);

export const BillingIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4094 0.121271C11.7844 -0.0404238 12.2156 -0.0404238 12.5906 0.121271L23.0906 4.62141C23.7469 4.90313 24.1125 5.60625 23.9672 6.3C23.8266 6.99844 23.2125 7.45782 22.4578 7.5V7.875C22.4578 8.49844 21.9984 9 21.3328 9H2.58281C2.00344 9 1.45781 8.49844 1.45781 7.875V7.5C0.787969 7.45782 0.173813 6.99844 0.0306004 6.3C-0.112593 5.60625 0.254485 4.90313 0.908906 4.62141L11.4094 0.121271ZM12 6C12.8297 6 13.5 5.32969 13.5 4.50001C13.5 3.67172 12.8297 3.00001 12 3.00001C11.1703 3.00001 10.5 3.67172 10.5 4.50001C10.5 5.32969 11.1703 6 12 6ZM5.95781 19.5H7.83281V10.5H10.8328V19.5H13.125V10.5H16.125V19.5H18V10.5H21V19.7016C21.0281 19.7156 21.0562 19.6922 21.0844 19.7531L23.3344 21.2531C23.8828 21.6188 24.1266 22.3031 23.9344 22.9359C23.7422 23.5688 23.1609 24 22.5 24H1.45781C0.839063 24 0.255844 23.5688 0.0643129 22.9359C-0.127265 22.3031 0.117891 21.6188 0.667969 21.2531L2.91797 19.7531C2.94469 19.6922 2.97234 19.7156 2.95781 19.7016V10.5H5.95781V19.5Z"
      fill="#0F6AF5"
    />
  </svg>
);

export const AtIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.74064 0.971791C5.36017 1.83054 1.83283 5.3621 0.975017 9.7421C-0.320607 16.3468 4.19298 22.2202 10.3078 23.1062C11.1989 23.2415 12.0033 22.5315 12.0033 21.6301L12.0033 21.599C12.0033 20.8612 11.467 20.2452 10.7452 20.1346C6.79127 19.5262 3.75143 16.0973 3.75143 11.969C3.75143 7.14555 7.90502 3.27368 12.817 3.74711C17.108 4.16285 20.2514 8.02446 20.2514 12.3346V13.0921C20.2514 14.1276 19.4105 14.9695 18.3764 14.9695C17.3424 14.9695 16.501 14.1276 16.501 13.0921V7.46243C16.501 7.04772 16.1653 6.71149 15.7505 6.71149L14.2514 6.71166C13.9093 6.71166 13.6327 6.94566 13.5427 7.25916C12.3778 6.68963 11.0002 6.49134 9.50861 7.01981C7.69221 7.66341 6.31549 9.31247 6.05299 11.2217C5.60847 14.4565 8.10658 17.2217 11.2514 17.2217C12.4908 17.2217 13.6153 16.7743 14.5135 16.0554C15.6385 17.5226 17.5711 18.3378 19.6416 17.8128C21.8063 17.311 23.25 15.1923 23.2078 12.9938V12.0141C23.2078 5.02038 16.9313 -0.437364 9.74064 0.971791ZM11.2078 14.2641C9.96704 14.2641 8.95783 13.2535 8.95783 12.0118C8.95783 10.7701 9.96704 9.75945 11.2078 9.75945C12.4486 9.75945 13.4578 10.7701 13.4578 12.0118C13.4578 13.2535 12.4922 14.2641 11.2078 14.2641Z"
      fill="#0F6AF5"
    />
  </svg>
);

export const PhoneIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9625 18.1406L22.8727 22.8656C22.7196 23.5336 22.1325 24.0009 21.4448 24.0009C9.61875 24 0 14.3813 0 2.55472C0 1.86753 0.467297 1.27972 1.13531 1.12737L5.86031 0.0375317C6.54844 -0.121937 7.25156 0.23525 7.5375 0.886906L9.71813 5.97284C9.97303 6.5719 9.80109 7.26987 9.29719 7.68143L6.77344 9.70783C8.36625 12.9525 11.0044 15.5906 14.25 17.1844L16.3162 14.6625C16.7235 14.1572 17.4267 13.9824 18.0258 14.2418L23.1117 16.422C23.7234 16.7438 24.1219 17.4563 23.9625 18.1406Z"
      fill="#0F6AF5"
    />
  </svg>
);

export const PersonIcon = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4573_21342)">
      <path d="M9.5 0C4.52891 0 0.5 4.02891 0.5 9C0.5 13.9711 4.52891 18 9.5 18C14.4711 18 18.5 13.9711 18.5 9C18.5 4.02891 14.4711 0 9.5 0ZM9.5 4.5C10.8982 4.5 12.0312 5.63344 12.0312 7.03125C12.0312 8.42906 10.8992 9.5625 9.5 9.5625C8.10219 9.5625 6.96875 8.42906 6.96875 7.03125C6.96875 5.63344 8.10078 4.5 9.5 4.5ZM9.5 15.75C7.63918 15.75 5.95273 14.9931 4.7293 13.7711C5.29883 12.3012 6.70508 11.25 8.375 11.25H10.625C12.2963 11.25 13.7026 12.3005 14.2707 13.7711C13.0473 14.9941 11.3598 15.75 9.5 15.75Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_4573_21342">
        <rect width="18" height="18" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>
);

export const CommentIcon = ({ color }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4649_23140)">
      <path
        d="M19.9656 2.46484V13.6797C19.9656 15.0566 18.8426 16.1445 17.4656 16.1445H11.8406L6.96172 19.8039C6.65411 20.0285 6.21485 19.8058 6.21485 19.425V16.1445H2.46485C1.08789 16.1445 -0.0351562 15.0215 -0.0351562 13.6797V2.46484C-0.0351562 1.08789 1.08789 0 2.46485 0H17.4649C18.8758 0 19.9656 1.12305 19.9656 2.46484Z"
        fill={color}/>
    </g>
    <defs>
      <clipPath id="clip0_4649_23140">
      <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export const DarkModeMoon = () => (
  <svg className="darkModeMoon" width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 64C8 33.05 33.075 8 63.95 8C66.79 8 71.375 8.417 74.175 8.9365C76.579 9.38075 77.1125 12.594 74.9948 13.7965C61.25 21.625 52.8 36.15 52.8 51.95C52.8 79.375 77.7275 100.2 104.875 95.025C107.265 94.5738 108.945 97.356 107.402 99.2625C96.975 112.15 81.2 120 63.95 120C33.025 120 8 94.9 8 64Z"
      fill="#A77B39"
    />
  </svg>
)

export const DropdownMenu = () => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 3C0 2.44781 0.447812 2 1 2H13C13.5531 2 14 2.44781 14 3C14 3.55313 13.5531 4 13 4H1C0.447812 4 0 3.55313 0 3ZM0 8C0 7.44687 0.447812 7 1 7H13C13.5531 7 14 7.44687 14 8C14 8.55313 13.5531 9 13 9H1C0.447812 9 0 8.55313 0 8ZM13 14H1C0.447812 14 0 13.5531 0 13C0 12.4469 0.447812 12 1 12H13C13.5531 12 14 12.4469 14 13C14 13.5531 13.5531 14 13 14Z"
      fill="#1F2020"
    />
  </svg>

)

export const DarkDropdownMenu = () => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 3C0 2.44781 0.447812 2 1 2H13C13.5531 2 14 2.44781 14 3C14 3.55313 13.5531 4 13 4H1C0.447812 4 0 3.55313 0 3ZM0 8C0 7.44687 0.447812 7 1 7H13C13.5531 7 14 7.44687 14 8C14 8.55313 13.5531 9 13 9H1C0.447812 9 0 8.55313 0 8ZM13 14H1C0.447812 14 0 13.5531 0 13C0 12.4469 0.447812 12 1 12H13C13.5531 12 14 12.4469 14 13C14 13.5531 13.5531 14 13 14Z"
      fill="#CDCDD5"
    />
  </svg>
)

export const ExitIcon = ({ darkMode }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M12.7055 11.2939C13.0961 11.6845 13.0961 12.3172 12.7055 12.7078C12.5118 12.9031 12.2555 13 11.9993 13C11.7431 13 11.4875 12.9024 11.2925 12.7071L7.99961 9.4159L4.70705 12.7063C4.51176 12.9031 4.25584 13 3.99992 13C3.744 13 3.4884 12.9031 3.29295 12.7063C2.90235 12.3157 2.90235 11.6829 3.29295 11.2923L6.58644 7.99883L3.29295 4.7069C2.90235 4.3163 2.90235 3.68354 3.29295 3.29295C3.68354 2.90235 4.3163 2.90235 4.7069 3.29295L7.99961 6.588L11.2931 3.29451C11.6837 2.90391 12.3165 2.90391 12.7071 3.29451C13.0976 3.6851 13.0976 4.31787 12.7071 4.70846L9.41356 8.00195L12.7055 11.2939Z"
    fill={darkMode ? "white" : "#1F2020"}
  />
  </svg>
)