import React, { useState } from "react";
import { ScaleIcon, FileFolderIcon, WebIcon, TimelineIcon, CommentIcon } from "../icons/icons";
import * as styles from "./mobileFeatures.module.css";

const icons = [
  {
    icon: ScaleIcon,
    url: "#features-processes",
  },
  {
    icon: FileFolderIcon,
    url: "#features-documents",
  },
  {
    icon: TimelineIcon,
    url: "#features-timeline",
  },
  {
    icon: CommentIcon,
    url: "#features-collaborate",
  },
  {
    icon: WebIcon,
    url: "#features-assets",
  },
];

const MobileFeatures = ({ offsetY, clientHeight }) => {
  const [isHovering, setIsHovering] = useState(5);
  const top = clientHeight * 2;

  const active = () => {
    if (offsetY >= clientHeight * 3) {
      if (offsetY <= clientHeight * 4) return 0;
      else if (offsetY >= clientHeight * 4 && offsetY < clientHeight * 5) return 1;
      else if (offsetY >= clientHeight * 5 && offsetY < clientHeight * 6) return 2;
      else if (offsetY >= clientHeight * 6 && offsetY < clientHeight * 7) return 3;
      else return 4;
    }
    return 5;
  }

  return (
    <div id="features" className={styles.features}>
      <nav className={styles.featuresNav}
        style={{
          display: (offsetY < top || offsetY > (clientHeight*7.5)) ? "none" : "flex",
        }}
      >
        {
          icons.map((icon, i) => (
            <a
              href={icon.url}
              className={styles.featuresIcons}
              onMouseEnter={() => setIsHovering(i)}
              onMouseLeave={() => setIsHovering(null)}
              style={{
                background: active() === i && '#edeef0',
                display: (offsetY < (top) || offsetY > (clientHeight*7.5)) ? "none" : "flex",
              }}
            >
              <icon.icon
                color={active() === i || isHovering === i ? '#006cfa' : '#CDCDD5'}
              />
            </a>
          ))
        }
      </nav>

      <section className={styles.firstSlide}>
        <h1>Driven by best practices.</h1>
        <h3>Stop searching through emails, spreadsheets and documents</h3>
      </section>
    </div>
  )
};

export default MobileFeatures;