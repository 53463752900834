// extracted by mini-css-extract-plugin
export var pageTitle = "home-module--pageTitle--wRbda";
export var herosection = "home-module--herosection--P9YSl";
export var mobileHerosection = "home-module--mobileHerosection--SGz5Y";
export var productPreview = "home-module--productPreview--C6A47";
export var productCover = "home-module--productCover--s2WDu";
export var opaqueCover = "home-module--opaqueCover--Bo2Oz";
export var landingText = "home-module--landingText--dQHLd";
export var mobileText = "home-module--mobileText--JLPJ6";
export var signupButton = "home-module--signupButton--SyfE5";
export var col1 = "home-module--col1--cxGFr";
export var center = "home-module--center--UUvGt";
export var ctaContainer = "home-module--ctaContainer--etzEt";
export var ctaHeading = "home-module--ctaHeading--j9Uzq";
export var ctaText = "home-module--ctaText--wM+Bk";
export var ctaButton = "home-module--ctaButton--bS50l";
export var ctaDescription = "home-module--ctaDescription--tkt8f";
export var showMore = "home-module--showMore--HwK0l";
export var pricingContainer = "home-module--pricingContainer--qT-q3";
export var pricingImage = "home-module--pricingImage--tV3T0";
export var pricingText = "home-module--pricingText--YsUL6";
export var mobilePricingBg = "home-module--mobilePricingBg--Z50R8";