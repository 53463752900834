import React from "react";
import classNames from "classnames";
import docs0 from "../../images/docs0.png";
import docs1 from "../../images/docs1.png";
import * as styles from "./folder.module.css"

export default function Folder({ className, offsetY, clientHeight }) {
  return (
    <main 
      id="features-documents"
      className={classNames(className, styles.folderContainer)}
      style={{ opacity: offsetY <= clientHeight*9.25 ? 0 : 1, transition: 'opacity .5s'}}
    >
      <img src={docs0} className={styles.docs0} alt="Documents that can be uploaded by users."
        style={{ opacity: offsetY >= clientHeight*11.1 ? 0 : 1 }}
      />
      <img src={docs1} className={styles.docs1} alt="Panel to edit documents"
        style={{ opacity: offsetY <= clientHeight*10.25 || offsetY >= clientHeight*11.1 ? 0 : 1 }}
      />
  
      <section
        style={{ opacity: offsetY >= clientHeight*11.1 && 0, transition: 'opacity .33s' }}
      >
        <h3>Upload and track documents in a secure document repository.</h3>
      </section>
    </main>
  )
};