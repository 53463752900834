import React from "react";
import productPreview from "../../images/product-preview.png";
import payment from "../../images/payment.png";
import { PersonIcon } from "../icons/icons";
import * as styles from "./home.module.css";

export default function Home({ setOpen, offsetY, clientHeight, isMobile }) {
  const endOfSection = clientHeight*.9;
  const imageCss = 1 - ((offsetY - endOfSection)*.001)

  const handleClick = () => {
    if (isMobile) setOpen(true);
    else window.open("https://app.jonahdirect.com/auth/register")
  }

  if (isMobile) return (
    <main id="home" className={isMobile ? styles.mobileHerosection : styles.herosection}
      style={{height: "200vh", overflow: 'hidden'}}
    >
      <img src={productPreview} width="100%"
        className={styles.productPreview}
        style={{
          transform: `translateY(${offsetY * .58}px)`,
          opacity: imageCss,
          visibility: imageCss < -5 && 'hidden',
          display: imageCss < .5 && 'none'
        }}
        alt="Table showing assets and loans in various stages of foreclosure."
      />
      <div
        className={styles.productCover}
        style={{
          transform: `translateY(${offsetY * .58}px)`,
          opacity: imageCss,
          visibility: imageCss < -5 && 'hidden',
          display: imageCss < .5 && 'none'
        }}
      />
      <div
        className={styles.opaqueCover}
        style={{ opacity: 0 + (offsetY / 200) }}
      />

      <section
        className={styles.mobileText}
        style={{
          opacity: 1 - (offsetY / 450),
          display: offsetY > 350 && 'none'
        }}
      >
        <h1 className={styles.pageTitle}>Manage your default portfolio with precision.</h1>
        {/* <button
          onClick={setOpen}
          className={styles.signupButton}
        >
          <PersonIcon />
          Sign Up
        </button>
        <h4>14 days free trial.</h4> */}
      </section>

      {/* {offsetY > clientHeight*.5 && offsetY < clientHeight &&
        <div className={styles.mobilePricingBg}/>
      }
      {(offsetY > clientHeight*.5 && offsetY < clientHeight) &&
        (
          <section
            style={{
              top: "70% !important"
            }}
            className={styles.mobileText}
          >
            <h1 className={styles.pageTitle}>Start as low as</h1>
            <h1 className={styles.pageTitle} style={{marginTop: "-1vh", marginBottom: "4vh"}}>$50.00/mo*</h1>
            <button
              onClick={handleClick}
              className={styles.signupButton}
              >
              <PersonIcon />
              Sign up
            </button>
            <h4>*Includes 5 Foreclosure Assets</h4>
            <h4>Assets above the count are priced at $5/mo per asset.</h4>
          </section>
        )
      } */}
    </main>
  )


  return (
    <main id="home" className={styles.herosection}
      style={{height: "200vh"}}
    >
      <div
        className={styles.opaqueCover}
        style={{ opacity: 0 + (offsetY / 200) }}
      />
      <img src={productPreview} width="100%"
        className={styles.productPreview}
        style={{
          transform: `translateY(${offsetY * .3}px)`,
          opacity: imageCss,
          visibility: imageCss < -5 && 'hidden',
          display: imageCss < .5 && 'none',
          filter: offsetY > clientHeight*.80 && 'blur(2px)',
        }}
        alt="Table showing assets and loans in various stages of foreclosure."
      />
      {/* {(offsetY > clientHeight*.55 && offsetY < clientHeight*1.3) &&
        (
          <main className={styles.pricingContainer}>
            <img width="22%"
              className={styles.pricingImage}
              src={payment}
              alt="Form to enter card details."
              style={{ transform: `translateY(-${offsetY*.04}px)` }}
            />
            <section
              className={styles.pricingText}
              style={{
                transform: `translateY(-${offsetY*.3}px)`
              }}
            >
              <h1 className={styles.pageTitle}>Start as low as</h1>
              <h1 className={styles.pageTitle} style={{marginTop: "-1vh", marginBottom: "4vh"}}>$50.00/mo*</h1>
              <button
                onClick={handleClick}
                className={styles.signupButton}
                >
                <PersonIcon />
                Sign up
              </button>
              <h3>*Includes 5 Foreclosure Assets</h3>
              <h3>Assets above the count are priced at $5/mo per asset.</h3>
            </section>
          </main>
        )
      } */}

      <section
        className={styles.landingText}
        style={{
          opacity: 1 - (offsetY / 450),
          display: offsetY > 350 && 'none'
        }}
      >
        <h1 className={styles.pageTitle}>Manage your default portfolio with precision.</h1>
        {/* <button
          onClick={handleClick}
          className={styles.signupButton}
        >
          <PersonIcon />
          Sign up
        </button>
        <h4>14 days free trial.</h4> */}
      </section>
    </main>
  );
}
