import React from "react";
import Footer from "../Footer/Footer";
import bg from "../../images/dark-bg.png";
import { DarkModeMoon } from "../icons/icons";
import * as styles from "./darkMode.module.css";

export default function DarkMode({ darkMode, isMobile }) {
  return (
    <main id="experience" className={styles.darkContainer}>
      <img src={bg} className={styles.darkBg} alt="Shot of Jonah Direct in dark mode."/>

      <section className={styles.darkModeText}>
        <h1>Into the night.</h1>
        <h3>Dark theme for the night owls in your life.</h3>
        {!isMobile && <DarkModeMoon />}
      </section>

      <Footer darkMode={darkMode} />
    </main>
  )
}