import React from 'react';
import logo from "../../images/Icon.svg";
import * as styles from "./loading.module.css";

export default function Loading() {
  return (
    <div className={styles.loadingContainer} id="loader-wrapper">
      <img src={logo}
        alt="Jonah Direct Logo"
        style={{height: '40px', width: '40px'}}
      />
      <div className={styles.loader}>
        <div className={styles.loaderBar}></div>
      </div>
    </div>
  );
}