import React from "react";
import classNames from "classnames";
import taskContainer from "../../images/task-container.png";
import task0 from "../../images/task0.png";
import task1 from "../../images/task1.png";
import task2 from "../../images/task2.png";
import task3 from "../../images/task3.png";
import task4 from "../../images/task4.png";
import taskEdit from "../../images/task-edit.png";
import taskActivity from "../../images/task-activity.png";
import holdManagement from "../../images/hold-management.png"
import * as styles from "./comment.module.css";

export default function Comment({ className, offsetY, clientHeight }) {
  return (
    <main
      id="features-collaborate"
      className={classNames(className, styles.commentContainer)}
      style={{ opacity: offsetY <= clientHeight*13.6 ? 0 : 1, transition: 'opacity .5s'}}
    >
      <section>
        <h3
          style={{ opacity: offsetY >= clientHeight*17.3 && 0, transition: 'opacity .33s' }}
        >Track tasks, communicate and collaborate with your team members.</h3>
        <img src={holdManagement}
          alt="Hold Management"
          style={{
            opacity: 1 - ((offsetY - (clientHeight*17.33))),
            width: '9vw',
            top: clientHeight < 1200 && `${71 - offsetY/clientHeight}%`
          }}
        />
      </section>
        <img src={taskContainer} className={styles.taskContainer} alt="Panel that contains tasks"
          style={{ opacity: offsetY >= clientHeight*17.3 ? 0 : 1 }}
        />
        <img src={task0} className={styles.task0} alt="Example task 1"
          style={{ opacity: offsetY <= clientHeight*14.33 || offsetY >= clientHeight*17.3 ? 0 : 1  }}
        />
        <img src={task1} className={styles.task1} alt="Example task 2"
          style={{ opacity: offsetY <= clientHeight*14.67 || offsetY >= clientHeight*17.3  ? 0 : 1 }}
        />
        <img src={task2} className={styles.task2} alt="Example task 3"
          style={{ opacity: offsetY <= clientHeight*15 || offsetY >= clientHeight*17.3  ? 0 : 1 }}
        />
        <img src={task3} className={styles.task3} alt="Example task 4"
          style={{ opacity: offsetY <= clientHeight*15.33 || offsetY >= clientHeight*17.3  ? 0 : 1 }}
        />
        <img src={task4} className={styles.task4} alt="Example task 5"
          style={{ opacity: offsetY <= clientHeight*15.67 || offsetY >= clientHeight*17.3  ? 0 : 1 }}
        />
        <img src={taskEdit} className={styles.taskEdit}  alt="Panel to edit task"
          style={{ opacity: offsetY <= clientHeight*16 || offsetY >= clientHeight*17.3  ? 0 : 1 }}
        />
        <img src={taskActivity} className={styles.taskActivity} alt="Comments on a specific task"
          style={{ opacity: offsetY <= clientHeight*16.33 || offsetY >= clientHeight*17.3  ? 0 : 1 }}
        />
    </main>
  )
}