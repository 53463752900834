import React from "react";
import referral0 from "../../images/referral0.png";
import referral1 from "../../images/referral1.png";
import referral2 from "../../images/referral2.png";
import stateSpecific from "../../images/state-specific.png";
import * as styles from "./mobileScale.module.css";

export default function MScale() {
  return (
    <main className={styles.scale}
      id="features-processes"
    >
      <section className={styles.textContainer}>
        <h3>Choose Judicial or Non-Judicial processes based on the State or case.</h3>
        <img src={stateSpecific}
          alt="State Specific Processes"
          style={{
            width: '60vw',
            paddingBottom: '30px'
          }}
        />
      </section>

        <div style={{
          position: 'absolute',
          width: '100%',
          height: "50%",
          left: 0
        }}>
          <img src={referral0} className={styles.referralPic0} alt="List of assets that the user can upload"/>
          <img src={referral1} className={styles.referralPic1} alt="Dropdown that shows more options"/>
          <img src={referral2} className={styles.referralPic2} alt="Panel that allows users to refer the selected asset"/>
        </div>

    </main>
  )
}