import React from "react";
import bg from "../../images/experience2-bg.png";
import classNames from "classnames";
import * as styles from "./experienceTwo.module.css";

//17800
export default function ExperienceTwo({ offsetY, clientHeight }) {
  const top = clientHeight * 21;
  const curr = offsetY - top;

  return (
    <main className={styles.expContainer} id="experience">

      <img src={bg} className={classNames(styles.experienceBg)}
        alt="Features of the app are seperated from the main image, spread out and layered."
      />

      <section className={styles.expBotText}
        style={{ transform: `translateY(${curr * .2}px)`, }}
      >
        <h1>Complete situational awareness.</h1>
        <h3>
          Dashboards, filters, views, and search-able
          data make tracking details easy.
        </h3>
      </section>
    </main>
  )
}