import React from "react";
import classNames from "classnames";
import timeline0 from "../../images/timeline0.png";
import timeline1 from "../../images/timeline1.png";
import timelineText from "../../images/timeline-text.png";
import * as styles from "./timeline.module.css";

export default function Timeline({ className, offsetY, clientHeight }) {
  return (
    <main 
      id="features-timeline"
      className={classNames(className, styles.timelineContainer)}
      style={{ opacity: offsetY <= clientHeight*11.33 ? 0 : 1, transition: 'opacity .5s' }}
    >
      <img src={timeline0} className={styles.timeline0} alt="Timeline of an asset with a few events"
        style={{ opacity: offsetY >= clientHeight*13.25 && 0 }}
      />
      <img src={timeline1} className={styles.timeline1} alt="Sidebar of the timeline with extra comments"
        style={{ opacity: offsetY <= clientHeight*12.25 || offsetY >= clientHeight*13.25 ? 0 : 1 }}
      />

      <section>
        <h3
          style={{ opacity: offsetY >= clientHeight*13.4 && 0, transition: 'opacity .33s' }}
        >Stay on top of milestones with timeline measurement.</h3>
        <img src={timelineText}
          alt="Comprehensive Event Log"
          style={{
            opacity: 1 - ((offsetY - (clientHeight* 13.3))),
            width: '12vw',
            top: clientHeight < 900 && `${68 - offsetY/clientHeight}%`
          }}
        />
      </section>
    </main>
  )
}