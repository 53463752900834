import React, { useState, useRef, useEffect } from 'react';
import logo from "../../images/Icon.svg";
import darkLogo from "../../images/darkIcon.png";
import darkMobileText from "../../images/darkMobileText.png";
import headerText from "../../images/mobileHeaderText.png";
import { ExitIcon } from '../icons/icons';
import { PersonIcon, DropdownMenu, DarkDropdownMenu } from '../icons/icons';
import * as styles from "./mobileHeader.module.css";

export default function MobileHeader({ hidden, darkMode, setOpen, page }) {
  const [openDropdown, setOpenDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const clickSignIn = () => {
    setOpenDropdown(false);
    setOpen(true);
  }

  useEffect(() => {
    const closeDropdown = (event) => {
      const { current } = dropdownRef;
      if (current && openDropdown && !current.contains(event.target))
        setOpenDropdown(false);
    };

    const { current } = page;
    current.addEventListener("mousedown", closeDropdown)
    return () => current.removeEventListener('mousedown', closeDropdown); 
  }, [page, openDropdown])

  return (
    <nav
      className={styles.headerContainer}
      style={{ background: darkMode ? "black" : "white", visibility: hidden && 'hidden'}}
    >
      <a href="#home"
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}
        onClick={() => setOpenDropdown(false)}
      >
        <img src={darkMode ? darkLogo : logo} className={styles.headerLogo} alt="Miniature logo"/>
        <img src={darkMode ? darkMobileText : headerText} className={styles.headerText} alt="stylized text of product, Jonah Direct"/>
      </a>

      <div className={styles.dropdown}>
        <button onClick={() => setOpenDropdown(!openDropdown)}>
          { darkMode ? <DarkDropdownMenu /> : <DropdownMenu /> } 
        </button>
      </div>

      <div className={styles.dropdownContent}
        style={{ display: openDropdown ? 'flex' : 'none', backgroundColor: darkMode ? "black" : "white"  }}
        ref={dropdownRef}
      >
        <div className={styles.dropdownTop}
        >
          <button onClick={() => setOpenDropdown(false)}>
            <ExitIcon darkMode={darkMode}/>
          </button>
          <a
            onClick={() => setOpenDropdown(false)}
            style={{ color: darkMode ? "white" : "black" }}
            href="#home"
          >Our Product</a>
          <a
            onClick={() => setOpenDropdown(false)}
            style={{ color: darkMode ? "white" : "black" }}
            href="#features"
          >Features</a>
          <a
            onClick={() => setOpenDropdown(false)}
            style={{ color: darkMode ? "white" : "black" }}
            href="#experience"
          >Experience</a>
          <a
            onClick={() => setOpenDropdown(false)}
            style={{ color: darkMode ? "white" : "black" }}
            href="#contact"
          >Contacts</a>
        </div>

        <button onClick={clickSignIn} className={styles.ctaButton}>
          <PersonIcon /> Sign Up
        </button>
      </div>
    </nav>
  )
};
