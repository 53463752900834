import React from "react";
import classNames from "classnames";
import productPreview from "../../images/product-preview.png";
import timeline from "../../images/features-timeline.png";
import panel0 from "../../images/features-panel0.png";
import panel1 from "../../images/features-panel1.png";
import panel2 from "../../images/features-panel2.png";
import * as styles from "./ourProduct.module.css";

export default function OurProduct({ offsetY, clientHeight }) {
  const topOfSection = offsetY - clientHeight*1.6;
  const bottomOfSection = clientHeight * 4.5;

  return (
    <main id="product" className={styles.product}
      style={{ opacity: offsetY >= clientHeight*4.75 && 0 }}
    >
      <img src={timeline} className={styles.productTimeline}
        style={{
          opacity: offsetY < clientHeight*1.7 || offsetY > clientHeight*4.25 ? '0' : '1',
        }}
        alt="Shows the timeline feature of Jonah Direct."
      />
      <img src={productPreview} className={styles.productBg}
        alt="Example of an asset table with loans in different stages of foreclosure"
        style={{
          transition: `opacity .33s`,
          opacity: topOfSection <= 10
            ? 0
            : 1
        }}
      />

      <img src={panel0} className={styles.productPanel}
        alt="Panel showing users pending actions for both the user and the user's team"
        style={{ marginTop: "-31%", borderRadius: "6px", opacity: 1, zIndex: 0 }}
      />
      <img src={panel1} className={styles.productPanel}
        alt="The same panel with notifications for the user's team"
        style={{ marginTop: "-32%", borderRadius: "6px",
          opacity: offsetY >= clientHeight*2.2 ? 1 : 0,
          zIndex: 1
        }}
      />
      <img src={panel2} className={classNames(styles.productPanel)}
        alt="The same panel with notifications for the user"
        style={{ marginTop: "-33%", borderRadius: "6px",
          zIndex: 2,
          opacity: offsetY >= clientHeight*3 ? 1 : 0,
        }}
      />

      <section
        className={styles.productText}
        style={{
          transition: `opacity .2s`,
          opacity: topOfSection <= -200 || offsetY >= bottomOfSection ? '0' : '1'
        }}
      >
        <h1>A transparent process.</h1>
        <h3>
          Refer loans to your lawfirm and track progress
          and communication in a single tool.
        </h3>
      </section>
    </main>
  )
}