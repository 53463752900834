import React from "react";
import web0 from "../../images/web0.png";
import web1 from "../../images/web1.png";
import * as styles from "./mobileWeb.module.css";

export default function Web() {

  return (
    <main
      id="features-assets"
      className={styles.webContainer}
    >

      <div style={{
        position: 'absolute',
        width: '100%',
        height: "50%",
        left: 0
      }}>
        <img src={web0} className={styles.web0} alt="Application in loading state"/>
        <img src={web1} className={styles.web1} alt="Panel that allows users to import assets"/>
      </div>

      <section className={styles.textContainer}>
        <h3>Leverage API's to update portfolio data easily.</h3>
      </section>
    </main>
  )
}