import React, { useState } from "react";
import { ScaleIcon, FileFolderIcon, WebIcon, TimelineIcon, CommentIcon } from "../icons/icons";
import Scale from "./Scale";
import Folder from "./Folder";
import Timeline from "./Timeline";
import Comment from "./Comment";
import Web from "./Web";
import * as styles from "./features.module.css";

const icons = [
  {
    icon: ScaleIcon,
    component: <Scale />,
    url: "#features-processes",
  },
  {
    icon: FileFolderIcon,
    component: <Folder />,
    url: "#features-documents",
  },
  {
    icon: TimelineIcon,
    component: <Timeline />,
    url: "#features-timeline",
  },
  {
    icon: CommentIcon,
    component: <Comment />,
    url: "#features-collaborate",
  },
  {
    icon: WebIcon,
    component: <Web />,
    url: "#features-assets",
  },
];

const Features = ({ offsetY, clientHeight }) => {
  const [isHovering, setIsHovering] = useState(5);
  const top = clientHeight*6.3;

  const active = () => {
    if (offsetY >= clientHeight * 6.9) {
      if (offsetY <= clientHeight * 9.2) return 0;
      else if (offsetY >= clientHeight * 9.2 && offsetY < clientHeight * 11.33) return 1;
      else if (offsetY >= clientHeight * 11.33 && offsetY < clientHeight * 13.5) return 2;
      else if (offsetY >= clientHeight * 13.5 && offsetY < clientHeight * 17.5) return 3;
      else return 4;
    }
    return 5;
  }

  return (
    <div id="features" className={styles.features}>
      <nav className={styles.featuresNav}
        style={{
          display: (offsetY < (top - clientHeight*.25) || offsetY > (clientHeight*19)) ? "none" : "flex",
        }}
      >
        {
          icons.map((icon, i) => (
            <a
              href={icon.url}
              className={styles.featuresIcons}
              onMouseEnter={() => setIsHovering(i)}
              onMouseLeave={() => setIsHovering(null)}
              style={{
                background: active() === i && '#edeef0',
                opacity: (offsetY < (top - clientHeight*.1) || offsetY > (clientHeight*19.5)) ? "0" : "1"
              }}
            >
              <icon.icon
                color={active() === i || isHovering === i ? '#006cfa' : '#CDCDD5'}
              />
            </a>
          ))
        }
      </nav>

      <section className={styles.firstSlide}>
        <h1>Driven by best practices.</h1>
        <h3>Stop searching through emails, spreadsheets and documents</h3>
      </section>

      <Scale className={styles.featuresSlides} offsetY={offsetY} clientHeight={clientHeight}/>
      <Folder className={styles.featuresSlides} offsetY={offsetY} clientHeight={clientHeight}/>
      <Timeline className={styles.featuresSlides} offsetY={offsetY} clientHeight={clientHeight}/>
      <Comment className={styles.featuresSlides} offsetY={offsetY} clientHeight={clientHeight}/>
      <Web className={styles.featuresSlides} offsetY={offsetY} clientHeight={clientHeight}/>
    </div>
  )
};

export default Features;